import * as React from 'react';
import { graphql } from 'gatsby';
import Page from '../presentation/staff';

const StaffProfile = ({ data, path }) => {
  return <Page data={data.wpStaffMember} path={path} />;
};

export const pageQuery = graphql`
  query StaffQuery($id: String) {
    wpStaffMember(id: { eq: $id }) {
      id
      title
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
      }
      staffAcf {
        emailAddress
        profileBio
        jobTitle
        profileForeword
        profileImage {
          sourceUrl
        }
      }
      acfStaffMeta {
        areas {
          ... on WpSpecialistArea {
            title
          }
        }
        accreditations {
          ... on WpProfAccreditation {
            title
          }
        }
      }
    }
  }
`;

export default StaffProfile;
